import { useGLTF } from '@react-three/drei'
import { useState, useEffect } from 'react'

import { baseCharacters, steps } from './characterSteps'

let counter = 0
let skipCounter = 0
const ACCENT_IDS = [17, 18, 19, 20, 21, 22, 32, 33, 34, 35, 36, 37, 38]

const Title = ({ textRef, color }) => {
  const [posData, setPosData] = useState({ posX: -5, posY: 0, size: 10 })
  const [characters, setCharacters] = useState([])
  const [start, setStart] = useState(false)

  const { nodes } = useGLTF('/title.glb')

  useEffect(() => {
    onScreenSizeChanged()
    window.addEventListener('resize', onScreenSizeChanged)

    setTimeout(() => {
      setStart(true)
    }, 1000)

    return () => {
      window.removeEventListener('resize', onScreenSizeChanged)
    }
  }, [])

  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        if (counter > steps.length - 1) {
          clearInterval(interval)
          return
        }

        const currentStep = steps[counter]

        if (skipCounter < currentStep.skipCount && currentStep.skipCount > 0) {
          skipCounter++
          return
        }
        skipCounter = 0

        const currentCharacter = baseCharacters.find(
          item => item.id === currentStep.id
        )

        if (currentCharacter) {
          if (currentStep.operation === 'add') {
            setCharacters(state => [...state, currentCharacter])
          } else {
            setCharacters(state =>
              state.filter(item => item.id !== currentCharacter.id)
            )
          }
        }

        counter++
      }, 80)

      return () => {
        clearInterval(interval)
      }
    }
  }, [start])

  const onScreenSizeChanged = () => {
    const ratio = window.innerWidth / window.innerHeight
    setPosData(getDataFromRatio(ratio))
  }

  const getDataFromRatio = ratio => {
    if (ratio > 1.822) return { posX: -2.2, posY: 0, size: 1 }
    if (ratio > 1.515) return { posX: -1.8, posY: 0, size: 1 }
    if (ratio > 1.359) return { posX: -1.2, posY: 0, size: 1 }
    if (ratio > 1.096) return { posX: -1.2, posY: 0, size: 0.8 }
    if (ratio > 0.779) return { posX: -1, posY: 0, size: 0.5 }
    if (ratio > 0.65) return { posX: -0.8, posY: 1, size: 0.5 }
    if (ratio > 0.569) return { posX: -0.4, posY: 1, size: 0.4 }
    if (ratio > 0.501) return { posX: -0.2, posY: 1, size: 0.4 }
    if (ratio > 0.385) return { posX: 0, posY: 1, size: 0.4 }
    return { posX: 0, posY: 1, size: 0.4 }
  }

  return (
    <group
      ref={textRef}
      position={[posData.posX, posData.posY, 0]}
      scale={posData.size}
    >
      {characters.map((item, index) => (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes[item?.name].geometry}
          //material={materials.sides}
          scale={1}
        >
          {ACCENT_IDS.some(item1 => item1 === item.id) ? (
            <meshNormalMaterial />
          ) : (
            <meshStandardMaterial attach="material" color={color} />
          )}
        </mesh>
      ))}
    </group>
  )
}

export default Title

useGLTF.preload('/title.glb')

import styled from 'styled-components'

import { colors } from '../../styles/colors'
import dottedBg from '../../assets/imgs/dotted-bg.png'

export const SectionTitle = styled.h2`
  position: absolute;
  top: 150px;
  display: flex;
  text-align: center;
  font-size: 45px;
  color: ${colors.main};
  font-family: gotham, sans-serif;

  @media only screen and (max-width: 730px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 32px;
  }
`

export const SectionContainer = styled.div<{
  clipLeft: boolean
  top: number
  color: string
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ color }) => color};
  padding: 150px 0 200px 0;
  clip-path: ${({ clipLeft }) =>
    clipLeft
      ? 'polygon(0 0, 100% 13%, 100% 100%, 0% 100%)'
      : 'polygon(0 13%, 100% 0, 100% 100%, 0% 100%)'};
  margin-top: ${({ top }) => top}px;

  @media only screen and (max-width: 800px) {
    clip-path: ${({ clipLeft }) =>
      clipLeft
        ? 'polygon(0 0, 100% 8%, 100% 100%, 0% 100%)'
        : 'polygon(0 8%, 100% 0, 100% 100%, 0% 100%)'};
  }

  @media only screen and (max-width: 730px) {
    clip-path: ${({ clipLeft }) =>
      clipLeft
        ? 'polygon(0 0, 100% 6%, 100% 100%, 0% 100%)'
        : 'polygon(0 6%, 100% 0, 100% 100%, 0% 100%)'};
  }

  @media only screen and (max-width: 460px) {
    clip-path: ${({ clipLeft }) =>
      clipLeft
        ? 'polygon(0 0, 100% 4%, 100% 100%, 0% 100%)'
        : 'polygon(0 4%, 100% 0, 100% 100%, 0% 100%)'};
  }
`

export const TechTitle = styled.h3<{ left: boolean; color: string }>`
  position: absolute;
  top: 50%;
  ${({ left }) => (left ? 'right: -300px' : 'left: -300px')};
  ${({ left }) =>
    left ? 'transform: rotate(-90deg)' : 'transform: rotate(90deg)'};
  display: flex;
  text-align: center;
  font-size: 140px;
  color: ${({ color }) => color};
  font-family: coolvetica, gotham, sans-serif;
  opacity: 0.1;

  @media only screen and (max-width: 730px) {
    font-size: 100px;
    ${({ left }) => (left ? 'right: -200px' : 'left: -200px')};
  }

  @media only screen and (max-width: 460px) {
    font-size: 80px;
    ${({ left }) => (left ? 'right: -150px' : 'left: -150px')};
  }
`

export const BgImage = styled.image<{ left: boolean }>`
  position: absolute;
  top: 0;
  ${({ left }) => (left ? 'left: 0' : 'right: 0')};
  ${({ left }) => (left ? '' : 'transform: rotate(180deg)')};
  ${({ left }) =>
    left ? 'filter: brightness(0%)' : 'filter: brightness(100%)'};
  width: 30%;
  height: 100%;
  opacity: 0.05;
  background-position-x: -400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${`url(${dottedBg})`};
  pointer-events: none;
`

export const InfoContainer = styled.div<{
  opacity: number
  left: boolean
  below: boolean
}>`
  display: flex;
  ${({ below }) => (below ? '' : 'position: absolute')};
  ${({ left }) => (left ? 'right: 17%' : 'left: 17%')};
  width: ${({ below }) => (below ? 80 : 38)}%;
  max-width: 700px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 1s ease;
`

export const InfoTitle = styled.h2<{ color: string }>`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: gotham, sans-serif;
  font-size: 36px;
  color: ${({ color }) => color};
  margin-bottom: 20px;

  @media only screen and (max-width: 730px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 36px;
  }
`

export const InfoDescription = styled.p<{ color: string }>`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: open-sans, sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: ${({ color }) => color};
  line-height: 30px;

  @media only screen and (max-width: 730px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 18px;
  }
`

export const TechContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

export const TechContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0 30px 0;
  flex-wrap: wrap;
`

export const OtherTechTitle = styled.p<{ color: string }>`
  text-align: center;
  align-items: center;
  font-family: gotham, sans-serif;
  font-size: 20px;
  color: ${({ color }) => color};

  @media only screen and (max-width: 730px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 20px;
  }
`

export const TechImg = styled.img`
  height: 50px;
  margin: 10px;

  @media only screen and (max-width: 730px) {
    height: 50px;
  }

  @media only screen and (max-width: 460px) {
    height: 40px;
  }
`

export const DistributionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px 0 30px 0;
`

export const DistributionButton = styled.button`
  position: relative;
  display: flex;
  background-color: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`

export const DistributionImg = styled.img`
  height: 50px;

  @media only screen and (max-width: 730px) {
    height: 50px;
  }

  @media only screen and (max-width: 460px) {
    height: 40px;
  }
`

export const DistributionSoonContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  border-radius: 10px;
`
export const DistributionSoonText = styled.p`
  color: ${colors.white};
  font-size: 18px;
  font-weight: 700;
`

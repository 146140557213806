import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './assets/fonts/GothamBlack.otf'
import './assets/fonts/GothamBold.otf'
import './assets/fonts/GothamBoldItalic.ttf'
import './assets/fonts/GothamBook.ttf'
import './assets/fonts/GothamBookItalic.otf'
import './assets/fonts/GothamLight.otf'
import './assets/fonts/GothamLightItalic.ttf'
import './assets/fonts/GothamMedium.ttf'
import './assets/fonts/GothamMediumItalic.ttf'
import './assets/fonts/GothamThin.otf'
import './assets/fonts/GothamThinItalic.otf'
import './assets/fonts/GothamUltraItalic.otf'
import './assets/fonts/GothamXLight.otf'
import './assets/fonts/GothamXLightItalic.otf'

import App from './App'

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three';

export default function Text({
  position,
  rotation,
  scale
}) {
  const [posData, setPosData] = useState({ posX: 0, posY: 0 , scale: 0})

  const { nodes, materials } = useGLTF('/planet.glb')

  const ref = useRef()

  useFrame(() => {
    if(ref.current){
      ref.current.rotateOnAxis(new Vector3(0, 1, 0).normalize(), 0.0005);
    }
  })

  useEffect(() => {
    onScreenSizeChanged()
    window.addEventListener('resize', onScreenSizeChanged);
    return () => window.removeEventListener('resize', onScreenSizeChanged);
  }, [])

  const onScreenSizeChanged = () => {
    const ratio = window.innerWidth / window.innerHeight;
    setPosData(getDataFromRatio(ratio))
  }

  const getDataFromRatio = (ratio) => {
    if(ratio > 1.822) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.515) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.359) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.096) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 0.779) return { posX: -200, posY: 100 , scale: -10 }
    if(ratio > 0.569) return { posX: -300, posY: 200 , scale: -20 }
    if(ratio > 0.501) return { posX: -300, posY: 200 , scale: -20 }
    return { posX: -300, posY: 200 , scale: -20 }
  }

  return (
    <group ref={ref} position={[position[0] + posData.posX, position[1] + posData.posY, position[2]]} scale={scale + posData.scale} rotation={rotation}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Icosphere007.geometry}
        material={materials.mat}
      />
    </group>
  )
}

useGLTF.preload('/planet.glb')

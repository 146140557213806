import { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './services/Firebase'
import Main from './pages/Main'
import './styles/global.scss'
import SuspenseLoader from './components/SuspenseLoader'
import Apps from './pages/Apps'
import SmartWorkoutPrivacy from './pages/SmartWorkout/Privacy'

const App: FC = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/apps" exact component={Apps} />
          <Route
            path="/smartworkout/privacy"
            exact
            component={SmartWorkoutPrivacy}
          />
        </Switch>
      </BrowserRouter>

      <SuspenseLoader />
    </>
  )
}

export default App

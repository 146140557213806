import styled from 'styled-components'

import { colors } from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15% 20px 15%;

  @media only screen and (max-width: 900px) {
    padding: 20px 5% 20px 5%;
  }

  @media only screen and (max-width: 730px) {
    padding: 20px 5% 20px 5%;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px;
  }
`
export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 800px) {
    margin-left: 60px;
  }

  @media only screen and (max-width: 730px) {
    margin-left: 40px;
  }
`

export const Logo = styled.h3`
  color: ${colors.black};
  font-size: 12px;
  font-family: gotham, sans-serif;
  padding: 15px;
  letter-spacing: 7px;
`

export const Item = styled.h1`
  color: ${colors.black};
  font-size: 16px;
  font-family: gotham, sans-serif;
  padding: 5px;
  background-color: transparent;
  border: 0;
  text-align: end;

  &:hover {
    cursor: pointer;
  }
`

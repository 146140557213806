import { Canvas } from '@react-three/fiber'
import { Suspense, useRef } from 'react'
import { Euler } from 'three'
import { Stars } from '@react-three/drei'

import Diorama from './Diorama'
import Title from './Title'
import Planet from './Planet'
import SuspenseLoader from '../../../components/SuspenseLoader'

const DioramaHeader = ({ mode }) => {
  const textRef = useRef()
  const eulerRef = useRef(new Euler(0, 0, 45))

  const onMouseMove = e => {
    if (textRef.current) {
      textRef.current.setRotationFromEuler(
        new Euler(
          ((e.clientY / e.target.offsetHeight - 0.5) * -Math.PI) / 20,
          ((e.clientX / e.target.offsetWidth - 0.5) * -Math.PI) / 20,
          0
        )
      )
    }
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '130vh',
        alignItems: 'flex-start',
        overflow: 'hidden'
      }}
      onMouseMove={onMouseMove}
    >
      <Suspense fallback={<SuspenseLoader />}>
        {/* {mode === 0 ? (
            <Canvas
              style={{ width: '100%', height: '100%' }}
              pixelRatio={window.devicePixelRatio}
              orthographic
              camera={{zoom: 100}}
              shadows
            >
              <directionalLight
                shadow-mapSize-height={1024}
                shadow-mapSize-width={1024}
                castShadow 
                intensity={0.8} 
                color="#fffff" 
                position={[-5, 5, 5]} 
              />
              
              <ambientLight intensity={0.15} />

              <Diorama position={[3, -2, -1]} scale={0.25} rotation={new Euler(0.1 * Math.PI, -0.3 * Math.PI, 0)} />
          </Canvas>
        ) : mode === 1 ? (
          <Canvas
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#0f0f0f'
            }}
            camera={{ fov: 80 }}
            pixelRatio={window.devicePixelRatio}
          >
            <directionalLight
              intensity={0.5}
              color="#C5E4FF"
              position={[5, 5, 5]}
            />
            <directionalLight
              intensity={0.1}
              color="#C0FFBE"
              position={[-3, 5, -5]}
            />
            <directionalLight
              intensity={0.06}
              color="#FFD66F"
              position={[-5, -10, -5]}
            />

            <Stars factor={8} depth={800} />

            <Planet
              position={[400, -150, -500]}
              scale={60}
              rotation={new Euler(0, 0, 45)}
            />
          </Canvas>
        ) : (
          <></>
        )} */}
        
        <Canvas
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#0f0f0f'
          }}
          camera={{ fov: 80 }}
          pixelRatio={window.devicePixelRatio}
        >
          <directionalLight
            intensity={0.5}
            color="#C5E4FF"
            position={[5, 5, 5]}
          />
          <directionalLight
            intensity={0.1}
            color="#C0FFBE"
            position={[-3, 5, -5]}
          />
          <directionalLight
            intensity={0.06}
            color="#FFD66F"
            position={[-5, -10, -5]}
          />

          <Stars factor={8} depth={800} />

          <Planet
            position={[400, -150, -500]}
            scale={60}
            rotation={eulerRef.current}
          />
        </Canvas>

        <Canvas
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh'
          }}
          camera={{ fov: 140, zoom: 4 }}
          pixelRatio={window.devicePixelRatio}
        >
          <directionalLight
            intensity={1}
            color="#fffff"
            position={[-5, 5, 5]}
          />

          <ambientLight intensity={0.15} />

          <Title textRef={textRef} color={mode === 0 ? '#3D3D3D' : '#EDEDED'} />
        </Canvas>
      </Suspense>
    </div>
  )
}

export default DioramaHeader

import { AppSectionType } from '../../components/AppSection'
import { colors } from '../../styles/colors'
import videoSmartWorkout from '../videos/smart-workout.mp4'
import videoFolclore from '../videos/folclore-fantastico.mp4'
import videoPetHealth from '../videos/pet-health.mp4'
import videoCoclub from '../videos/coclub.mp4'
import videoSafeguard from '../videos/safeguard.mp4'

export default [
  {
    title: 'Safeguard Global (Safeguard Global)',
    mainTech: 'React Native',
    description:
      'For over a decade, Safeguard Global has helped hundreds of organizations pay and hire workers around the world. Today, with over 900 clients and across 15 global offices, we enable business success through smarter, more efficient payroll, onboarding, time and expense management, and more.',
    video: videoSafeguard,
    top: -200,
    colors: {
      background: colors.white,
      tech: colors.main,
      text: colors.text,
      title: colors.main
    },
    techs: ['react', 'codepush', 'firebase', 'pendo'],
    distribution: [
      {
        type: 'app_store',
        link: 'https://apps.apple.com/br/app/safeguard-global/id1497499336'
      },
      {
        type: 'google_play',
        link: 'https://play.google.com/store/apps/details?id=com.globalplus'
      }
    ],
    showLatestTitle: true,
    left: true
  },
  {
    title: 'coClub (Rubcube)',
    mainTech: 'React Native',
    description:
      "Like many scientists, we believe there is a strong three-tiered connection between Mental health, Physical activity, and Socialization. Let's shake up our lives for the better.",
    video: videoCoclub,
    top: -200,
    colors: {
      background: colors.main,
      tech: colors.white,
      text: colors.white,
      title: colors.accent
    },
    techs: ['react', 'reanimated', 'firebase'],
    distribution: [
      {
        type: 'app_store',
        link: 'https://apps.apple.com/ng/app/coclub/1637133635'
      },
      {
        type: 'google_play',
        link: 'https://play.google.com/store/apps/details?id=app.coclub.mobile'
      }
    ],
    showLatestTitle: false,
    left: false
  },
  {
    title: 'Smart Workout',
    mainTech: 'React Native',
    description:
      'Workout more efficiently without wasting time and improving your gains. Get organize and create training plans to reach your goals.',
    video: videoSmartWorkout,
    top: -200,
    colors: {
      background: colors.accent,
      tech: colors.text,
      text: colors.text,
      title: colors.main
    },
    techs: ['react', 'reanimated'],
    distribution: [
      {
        type: 'app_store',
        soon: true,
        link: ''
      },
      {
        type: 'google_play',
        link: 'https://play.google.com/store/apps/details?id=com.higorbelem.smartworkout'
      }
    ],
    showLatestTitle: false,
    left: true
  },
  {
    title: 'PetHealth (Rubcube)',
    mainTech: 'React Native',
    description:
      'Have you ever imagined having everything you need for your pet in the palm of your hand? With our app this is possible.',
    video: videoPetHealth,
    top: -200,
    colors: {
      background: colors.white,
      tech: colors.main,
      text: colors.text,
      title: colors.main
    },
    techs: ['react', 'reanimated', 'firebase'],
    distribution: [
      {
        type: 'app_store',
        soon: false,
        link: 'https://apps.apple.com/us/app/pethealth/id1605192521'
      },
      {
        type: 'google_play',
        soon: false,
        link: 'https://play.google.com/store/apps/details?id=app.br.pethealth'
      }
    ],
    showLatestTitle: false,
    left: false
  },
  {
    title: 'Folclore Fantástico (Nextale)',
    mainTech: 'React Native',
    description:
      "Venture into the fantastic universe of folklore! Collect cards and build amazing decks from the world's most powerful mystical entities.",
    video: videoFolclore,
    top: -200,
    colors: {
      background: colors.main,
      tech: colors.white,
      text: colors.white,
      title: colors.accent
    },
    techs: ['react', 'redux', 'reanimated', 'firebase'],
    distribution: [
      {
        type: 'app_store',
        link: 'https://apps.apple.com/ng/app/folclore-fant%C3%A1stico/id1570517770'
      },
      {
        type: 'google_play',
        link: 'https://play.google.com/store/apps/details?id=br.com.nextale.cardbook.folclore'
      }
    ],
    showLatestTitle: false,
    left: true
  }
] as AppSectionType[]

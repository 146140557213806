/* eslint-disable no-use-before-define */
import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './styles'
import SideBarIcon from '../../assets/imgs/sidebar.svg'
import Sidebar from '../../components/Sidebar'
import AppSection from '../../components/AppSection'
import Header from './Header'
import About from './About'
import Companies from './Companies'
import Footer from '../../components/Footer'
import apps from '../../assets/static/apps'
import ArrowAnimation from '../../components/ArrowAnimation'

const Main: FC = () => {
  const history = useHistory()
  const sidebarVisible = useState(false)
  const [headerMode, setHeaderMode] = useState<number>()
  const [scrollPosition, setScrollPosition] = useState(0)

  const latestRef = useRef<any>()
  const aboutRef = useRef<any>()
  const companiesRef = useRef<any>()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    /* const lastHeaderMode = localStorage.getItem(
      'com.higorbelem:last-header-mode'
    )

    if (lastHeaderMode) {
      if (lastHeaderMode === '0') {
        setHeaderMode(1)
        localStorage.setItem('com.higorbelem:last-header-mode', '1')
      } else {
        setHeaderMode(0)
        localStorage.setItem('com.higorbelem:last-header-mode', '0')
      }
    } else {
      const mode = Math.round(Math.random())
      setHeaderMode(mode)
      localStorage.setItem(
        'com.higorbelem:last-header-mode',
        mode === 0 ? '0' : '1'
      )
    }*/
  }, [])

  const onSideBarPressed = (type: any) => {
    sidebarVisible[1](false)

    if (type === 'home') {
      window.scrollTo(0, 0)
      return
    }

    if (type === 'latest') {
      latestRef?.current?.scrollIntoView()
      return
    }

    if (type === 'about') {
      aboutRef?.current?.scrollIntoView()
      return
    }

    if (type === 'companies') {
      companiesRef?.current?.scrollIntoView()
    }

    if (type === 'apps') {
      history.push('/apps')
    }
  }

  return (
    <>
      <S.Container>
        <S.HeaderContainer>
          <Header mode={1} />

          <S.ArrowContainer>
            <ArrowAnimation visible={scrollPosition === 0} />
          </S.ArrowContainer>
        </S.HeaderContainer>

        <S.LatestContainer ref={latestRef}>
          {apps.slice(0, 3).map((item, index) => (
            <AppSection
              index={index}
              latestTitle="LATEST APPS"
              title={item.title}
              mainTech={item.mainTech}
              description={item.description}
              video={item.video}
              colors={item.colors}
              top={item.top}
              techs={item.techs}
              distribution={item.distribution}
              showLatestTitle={item.showLatestTitle}
              left={item.left}
            />
          ))}
        </S.LatestContainer>

        <S.MoreAppsButton onClick={() => history.push('/apps')}>
          <S.MoreApps>More Apps</S.MoreApps>
        </S.MoreAppsButton>

        <S.AboutContainer ref={aboutRef}>
          <About />
        </S.AboutContainer>

        <S.CompaniesContainer ref={companiesRef}>
          <Companies />
        </S.CompaniesContainer>

        <S.SidebarButton onClick={() => sidebarVisible[1](true)}>
          <img src={SideBarIcon} alt="Side Bar Icon" />
        </S.SidebarButton>

        <Footer onPress={onSideBarPressed} />
      </S.Container>

      <S.SidebarContainer opened={sidebarVisible[0]}>
        <Sidebar visible={sidebarVisible} onPress={onSideBarPressed} />
      </S.SidebarContainer>
    </>
  )
}

export default Main

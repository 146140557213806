/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'

import * as S from './styles'

type Props = {
  title: string
  img: any
  years: number
  style?: any
  percentage: number
}

const SectionTitle: FC<Props> = ({ style, img, title, years, percentage }) => {
  return (
    <S.Container style={style}>
      <S.Image src={img} />

      <S.Content>
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
          <S.Years>
            {years} {years > 1 ? 'years' : 'year'}
          </S.Years>
        </S.TitleContainer>

        <S.ExperienceBar>
          <S.ExperienceFilledBar percetage={percentage} />
        </S.ExperienceBar>
      </S.Content>
    </S.Container>
  )
}

export default SectionTitle

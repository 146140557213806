/* eslint-disable no-use-before-define */
import React, { FC, useEffect } from 'react'

import * as S from './styles'
import CloseIcon from '../../assets/imgs/close.svg'

type Props = {
  visible?: any
  onPress: (type: 'home' | 'latest' | 'about' | 'companies' | 'apps') => void
}

const Sidebar: FC<Props> = ({ visible, onPress }) => {
  useEffect(() => {
    document.body.style.overflow = visible[0] ? 'hidden' : 'auto'
  }, [visible])

  return (
    <S.Container>
      <S.BgImage />

      <S.Logo>HIGOR BELEM</S.Logo>

      <S.Nav>
        <S.Item onClick={() => onPress('home')}>HOME</S.Item>
        <S.Item onClick={() => onPress('latest')}>LATEST APPS</S.Item>
        <S.Item onClick={() => onPress('about')}>ABOUT ME</S.Item>
        <S.Item onClick={() => onPress('companies')}>COMPANIES</S.Item>
        <S.Item onClick={() => onPress('apps')}>APPS</S.Item>
      </S.Nav>

      <S.CloseButton onClick={() => visible[1](false)}>
        <S.CloseImg src={CloseIcon} alt="Close Icon" />
      </S.CloseButton>
    </S.Container>
  )
}

export default Sidebar

import styled from 'styled-components'

import { colors } from '../../styles/colors'
import dottedBg from '../../assets/imgs/dotted-bg.png'

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #ffffff;
`
export const BgImage = styled.image`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  opacity: 0.1;
  background-position-x: -200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${`url(${dottedBg})`};
  pointer-events: none;
  filter: invert(100%);
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 80px;

  @media only screen and (max-width: 800px) {
    margin-left: 60px;
  }

  @media only screen and (max-width: 730px) {
    margin-left: 40px;
  }
`

export const Logo = styled.h3`
  position: absolute;
  top: 30px;
  left: 30px;
  color: ${colors.black};
  font-size: 16px;
  font-family: gotham, sans-serif;
  padding: 15px;
  letter-spacing: 7px;
`

export const Item = styled.h1`
  color: ${colors.black};
  font-size: 40px;
  font-family: gotham, sans-serif;
  padding: 15px;
  background-color: transparent;
  border: 0;
  text-align: start;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 730px) {
    font-size: 30px;
  }
`
export const CloseImg = styled.img`
  width: 30px;
  height: 30px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border: 0;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`

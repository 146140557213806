import styled from 'styled-components'

import { colors } from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
  background-color: red;
  margin-bottom: 200px;
  background-color: ${colors.white};
`

export const Logo = styled.h3`
  color: ${colors.black};
  font-size: 14px;
  font-family: gotham, sans-serif;
  padding: 15px;
  letter-spacing: 7px;

  &:hover {
    cursor: pointer;
  }
`

/* eslint-disable no-use-before-define */
import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './styles'
import SideBarIcon from '../../assets/imgs/sidebar_black.svg'
import Sidebar from '../../components/Sidebar'
import AppSection from '../../components/AppSection'
import Footer from '../../components/Footer'
import apps from '../../assets/static/apps'
import Header from '../../components/Header'

const Apps: FC = () => {
  const history = useHistory()
  const sidebarVisible = useState(false)

  const latestRef = useRef<any>()
  const aboutRef = useRef<any>()
  const companiesRef = useRef<any>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSideBarPressed = (type: any) => {
    sidebarVisible[1](false)

    if (type === 'home') {
      history.push('/')
      return
    }

    if (type === 'latest') {
      history.push('/')
      return
    }

    if (type === 'about') {
      history.push('/')
      return
    }

    if (type === 'companies') {
      history.push('/')
    }
  }

  return (
    <>
      <S.Container>
        <Header />
        <S.LatestContainer ref={latestRef}>
          {apps.map((item, index) => (
            <AppSection
              index={index}
              title={item.title}
              mainTech={item.mainTech}
              description={item.description}
              video={item.video}
              colors={item.colors}
              top={item.top}
              techs={item.techs}
              distribution={item.distribution}
              showLatestTitle={item.showLatestTitle}
              latestTitle="APPS"
              left={item.left}
            />
          ))}
        </S.LatestContainer>

        <S.SidebarButton onClick={() => sidebarVisible[1](true)}>
          <img src={SideBarIcon} alt="Side Bar Icon" />
        </S.SidebarButton>

        <Footer onPress={onSideBarPressed} />
      </S.Container>

      <S.SidebarContainer opened={sidebarVisible[0]}>
        <Sidebar visible={sidebarVisible} onPress={onSideBarPressed} />
      </S.SidebarContainer>
    </>
  )
}

export default Apps

import styled from 'styled-components'

import { colors } from '../../../../styles/colors'

export const Container = styled.div<{
  left: boolean | undefined
  color: string
}>`
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
  align-items: center;
  ${({ left, color }) =>
    left
      ? `border-left: ${color} solid 10px`
      : `border-right: ${color} solid 10px`};
  border-radius: 5px;
  margin-top: 60px;
`

export const Title = styled.h3`
  display: flex;
  font-size: 30px;
  color: ${colors.main};
  font-family: gotham, sans-serif;
  margin: 0 10px 0 10px;
`

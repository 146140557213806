import styled from 'styled-components'

import { colors } from '../../../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: 5px;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`

export const Title = styled.h4`
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: ${colors.text};
  font-family: gotham, sans-serif;
`

export const Years = styled.h5`
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: ${colors.gray};
  font-family: gotham, sans-serif;
  margin-left: 20px;
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
`

export const ExperienceBar = styled.div`
  width: 100%;
  height: 14px;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 0px #00000020;
`

export const ExperienceFilledBar = styled.div<{ percetage: number }>`
  width: ${({ percetage }) => percetage}%;
  height: 100%;
  border-radius: 7px;
  background: ${`linear-gradient(90deg, ${colors.text}, ${colors.accent})`};
`

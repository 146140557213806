/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'

import nextaleLogo from '../../../assets/imgs/companies/nextale.png'
import paimLogo from '../../../assets/imgs/companies/paim.png'
import rubcubeLogo from '../../../assets/imgs/companies/rubcube.png'
import safeguardLogo from '../../../assets/imgs/companies/safeguard.png'
import * as S from './styles'

type Props = {}

const companies = [
  {
    title: 'Safeguard Global',
    subtitle:
      'We help you recruit and hire top talent, pay employees, analyze data and manage business in 170+ countries.',
    color: '#ffffff',
    logo: safeguardLogo,
    link: 'https://www.safeguardglobal.com/'
  },
  {
    title: 'Rubcube',
    subtitle:
      'We are a development, data engineering and design agency that works closely with founders and executives to help build your software.',
    color: '#1d1c3e',
    logo: rubcubeLogo,
    link: 'https://www.rubcube.com/'
  },
  {
    title: 'Nextale',
    subtitle:
      'We are an immersive and gamified reading app publisher. We have developed a new digital reading technology that transports the reader inside the stories.',
    color: '#774DA6',
    logo: nextaleLogo,
    link: 'https://www.nextale.com.br/'
  },
  {
    title: 'Paim',
    subtitle:
      'Paim is an agency with national operations. Since 1991, it has worked to help its customers grow.',
    color: '#ffffff',
    logo: paimLogo,
    link: 'https://www.linkedin.com/company/paim-comunicacao/'
  }
]

const Companies: FC<Props> = ({}) => {
  return (
    <S.Container>
      <S.Title>COMPANIES</S.Title>
      <S.Subtitle>
        Some companies that i had the pleasure to work with.
      </S.Subtitle>

      <S.CompaniesContainer>
        {companies.map(item => (
          <S.ItemContainer onClick={() => window.open(item.link, '_blank')}>
            <S.ItemImageContainer color={item.color}>
              <S.ItemImage src={item.logo} />
            </S.ItemImageContainer>
            <S.ItemTitleContainer>
              <S.ItemTitle>{item.title}</S.ItemTitle>
              <S.ItemSubtitle>{item.subtitle}</S.ItemSubtitle>
            </S.ItemTitleContainer>
          </S.ItemContainer>
        ))}
      </S.CompaniesContainer>
    </S.Container>
  )
}

export default Companies

import styled from 'styled-components'

import { colors } from '../../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 15% 120px 15%;
  max-width: 1920px;

  @media only screen and (max-width: 900px) {
    padding: 120px 5% 120px 5%;
  }
`

export const Title = styled.h2`
  display: flex;
  text-align: center;
  font-size: 45px;
  color: ${colors.main};
  font-family: gotham, sans-serif;

  @media only screen and (max-width: 730px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.h3`
  display: flex;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: ${colors.text};
  font-family: gotham, sans-serif;
  margin-top: 30px;

  @media only screen and (max-width: 730px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 18px;
  }
`

export const CompaniesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 50px;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 730px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 460px) {
    grid-template-columns: 1fr;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px #00000020;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

export const ItemImageContainer = styled.div<{ color: string }>`
  display: flex;
  background-color: ${({ color }) => color};
  align-items: center;
  justify-content: center;
`

export const ItemImage = styled.img`
  height: 150px;
  margin: 10px;
`

export const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const ItemTitle = styled.h4`
  font-weight: 300;
  font-size: 26px;
  color: ${colors.main};
  font-family: gotham, sans-serif;
`

export const ItemSubtitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  color: ${colors.text};
  font-family: gotham, sans-serif;
  margin-top: 10px;
  line-height: 24px;
`

import { Canvas, useFrame } from '@react-three/fiber'
import { Suspense, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';

import Phone from './Phone'

const DisableRender = () => useFrame(() => null, 1000)

const PhoneDisplay = ({fromLeft = true, video, index}) => {

  const [show, setShow] = useState(false);
  const [containerPosition, setContainerPosition] = useState(0)
  const ref = useRef()

  useEffect(() => {
    handleScroll();
  }, [])

  useEffect(() => {
    const domNode = ReactDOM.findDOMNode(ref?.current);
    setContainerPosition(domNode.getBoundingClientRect().top + window.scrollY)
  }, [ref?.current])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const domNode = ReactDOM.findDOMNode(ref?.current);

    if(domNode){
      const scroll = window.scrollY;
      const elementTopDistance = domNode.getBoundingClientRect().top + window.scrollY;
  
      setShow(elementTopDistance <= (scroll + (window.innerHeight * 2)) && elementTopDistance >= scroll - (window.innerHeight * 2));
    }
  }

  return (
    <div ref={ref} style={{ width: '100vw', height: '100vh', alignItems: 'flex-start', overflow: 'hidden' }}>
      <Canvas
        style={{ width: '100%', height: '100%' }}
        camera={{ fov: 80 }}
        pixelRatio={window.devicePixelRatio}
      >
        {!show && <DisableRender />}
        <directionalLight intensity={2} color="#f0f0f0" position={[fromLeft ? 10 : -10, 5, 5]} />

        <Suspense fallback={null}>
          <Phone containerPosition={containerPosition} video={video} fromLeft={fromLeft} index={index} show={show}/>
        </Suspense>
      </Canvas>
    </div>
  )
}

export default PhoneDisplay

import styled from 'styled-components'

import { colors } from '../../styles/colors'
import dottedBg from '../../assets/imgs/dotted-bg.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(-45deg, #7eb6ac, #b4d2cd, #b4d2cd);
`

export const SidebarContainer = styled.div<{ opened: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ opened }) => (opened ? '100vw' : '0px')};
  height: 100vh;
  background-color: ${colors.white};
  transition: width 0.6s ease;
  overflow: hidden;
`

export const LatestContainer = styled.div``

export const AboutContainer = styled.div``

export const CompaniesContainer = styled.div``

export const SectionTitle = styled.h2`
  display: flex;
  text-align: center;
  font-size: 64px;
  color: ${colors.main};
  font-family: gotham, sans-serif;
  margin-top: 200px;
`

export const SectionContainer = styled.div<{
  clipLeft: boolean
  top: number
  color: string
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ color }) => color};
  padding: 100px 0 100px 0;
  clip-path: ${({ clipLeft }) =>
    clipLeft
      ? 'polygon(0 0, 100% 13%, 100% 100%, 0% 100%)'
      : 'polygon(0 13%, 100% 0, 100% 100%, 0% 100%)'};
  margin-top: ${({ top }) => top}px;
`

export const TechTitle = styled.h3`
  position: absolute;
  right: -300px;
  top: 50%;
  transform: rotate(-90deg);
  display: flex;
  text-align: center;
  font-size: 140px;
  color: ${colors.main};
  font-family: coolvetica, gotham, sans-serif;
  opacity: 0.1;
`

export const BgImage = styled.image`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  opacity: 0.05;
  background-position-x: -400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${`url(${dottedBg})`};
  pointer-events: none;
  filter: brightness(0%);
`

export const InfoContainer = styled.div<{ opacity: number }>`
  display: flex;
  position: absolute;
  right: 17%;
  width: 40%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.5s ease;
`

export const InfoTitle = styled.h2`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: gotham, sans-serif;
  font-size: 46px;
  color: ${colors.main};
  margin-bottom: 20px;
`

export const InfoDescription = styled.p`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: open-sans, sans-serif;
  font-size: 26px;
  font-weight: 300;
  color: ${colors.text};
`

export const SidebarButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  background-color: transparent;
  border-width: 0;
  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 800px) {
    width: 70px;
    height: 70px;
  }

  @media only screen and (max-width: 730px) {
    width: 70px;
    height: 70px;
  }

  @media only screen and (max-width: 460px) {
    width: 70px;
    height: 70px;
  }
`

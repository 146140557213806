import styled from 'styled-components'

export const Container = styled.div<{ state: number }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ state }) => (state === 2 ? '0' : '100vw')};
  opacity: ${({ state }) => (state === 1 ? 0 : 1)};
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
`

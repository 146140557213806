/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'
import moment from 'moment'

import { colors } from '../../../styles/colors'
import SectionTitle from './SectionTitle'
import Skill from './Skill'
import * as S from './styles'

import linkedinIcon from '../../../assets/imgs/linkedin.png'
import githubIcon from '../../../assets/imgs/github.png'
import emailIcon from '../../../assets/imgs/email.png'

import blenderIcon from '../../../assets/imgs/techs/blender.svg'
import reactIcon from '../../../assets/imgs/techs/react.svg'
import nodeIcon from '../../../assets/imgs/techs/nodejs.svg'
import firebaseIcon from '../../../assets/imgs/techs/firebase.svg'
import reduxIcon from '../../../assets/imgs/techs/redux.svg'
import aiIcon from '../../../assets/imgs/techs/ai.svg'
import postgresIcon from '../../../assets/imgs/techs/postgres.svg'
import mysqlIcon from '../../../assets/imgs/techs/mysql.svg'
import psIcon from '../../../assets/imgs/techs/ps.svg'
import nextjsIcon from '../../../assets/imgs/techs/nextjs.svg'

type Props = {}

const skills = [
  {
    name: 'React Native',
    image: reactIcon,
    startDate: '01/01/2020',
    skillLevel: 95
  },
  {
    name: 'React Js',
    image: reactIcon,
    startDate: '01/01/2021',
    skillLevel: 70
  },
  {
    name: 'Node Js',
    image: nodeIcon,
    startDate: '01/01/2021',
    skillLevel: 60
  },
  {
    name: 'Redux',
    image: reduxIcon,
    startDate: '01/01/2021',
    skillLevel: 80
  },
  {
    name: 'Firebase',
    image: firebaseIcon,
    startDate: '01/01/2020',
    skillLevel: 70
  },
  {
    name: 'Postgresql',
    image: postgresIcon,
    startDate: '01/01/2020',
    skillLevel: 80
  },
  {
    name: 'MySQL',
    image: mysqlIcon,
    startDate: '01/01/2020',
    skillLevel: 80
  },
  {
    name: 'Photoshop',
    image: psIcon,
    startDate: '01/01/2021',
    skillLevel: 50
  },
  {
    name: 'Adobe Illustrator',
    image: aiIcon,
    startDate: '01/01/2021',
    skillLevel: 50
  },
  {
    name: 'Blender',
    image: blenderIcon,
    startDate: '01/01/2019',
    skillLevel: 75
  },
  {
    name: 'Next.js',
    image: nextjsIcon,
    startDate: '01/01/2022',
    skillLevel: 70
  }
]

const About: FC<Props> = ({}) => {
  return (
    <S.Container>
      <S.Title>ABOUT ME</S.Title>

      <SectionTitle left color={colors.main}>
        ABOUT
      </SectionTitle>
      <S.AboutText>
        Hi, I&apos;m Higor Belem, I am a Brazilian software developer with
        experience in developing mobile applications using React Native. While I
        am constantly striving to improve my skills and knowledge, I consider
        myself a dedicated and hard-working individual who is always eager to
        take on new challenges. My goal is to create user-friendly and efficient
        mobile apps that provide a positive experience for the end-user.
      </S.AboutText>

      <SectionTitle color={colors.accent}>SKILL SET</SectionTitle>
      <S.SkillsContainer>
        {skills.map(item => (
          <Skill
            title={item.name}
            img={item.image}
            years={moment().diff(moment(item.startDate), 'years')}
            percentage={item.skillLevel}
          />
        ))}
      </S.SkillsContainer>

      <SectionTitle left color={colors.text}>
        CONTACTS / INFO
      </SectionTitle>
      <S.ContactsContainer>
        <S.Contact
          onClick={() =>
            window.open('https://www.linkedin.com/in/higor-belem', '_blank')
          }
        >
          <S.ContactImage src={linkedinIcon} />
          <S.ContactLink>https://www.linkedin.com/in/higor-belem</S.ContactLink>
        </S.Contact>

        <S.Contact
          onClick={() => window.open('https://github.com/higorbelem', '_blank')}
        >
          <S.ContactImage src={githubIcon} />
          <S.ContactLink>https://github.com/higorbelem</S.ContactLink>
        </S.Contact>

        <S.Contact
          onClick={() => window.open('mailto:higorbelemdeoliveira@hotmail.com')}
        >
          <S.ContactImage src={emailIcon} />
          <S.ContactLink>higorbelemdeoliveira@hotmail.com</S.ContactLink>
        </S.Contact>
      </S.ContactsContainer>
    </S.Container>
  )
}

export default About

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'

import * as S from './styles'

type Props = {
  left?: boolean
  children: string
  color: string
}

const SectionTitle: FC<Props> = ({ children, left, color }) => {
  return (
    <S.Container left={left} color={color}>
      <S.Title>{children}</S.Title>
    </S.Container>
  )
}

export default SectionTitle

export const baseCharacters = [
    {id: 1, name: 'character_1'},
    {id: 2, name: 'character_2'},
    {id: 3, name: 'character_3'},
    {id: 4, name: 'character_4'},
    {id: 5, name: 'character_5'},
    {id: 6, name: 'character_6'},
    {id: 7, name: 'character_7'},
    {id: 8, name: 'character_8'},
    {id: 9, name: 'character_9'},
    {id: 10, name: 'character_10'},
    {id: 11, name: 'character_11'},
    {id: 12, name: 'character_12'},
    {id: 13, name: 'character_13'},
    {id: 14, name: 'character_14'},
    {id: 15, name: 'character_15'},
    {id: 16, name: 'character_16'},
    {id: 17, name: 'character_17'},
    {id: 18, name: 'character_18'},
    {id: 19, name: 'character_19'},
    {id: 20, name: 'character_20'},
    {id: 21, name: 'character_21'},
    {id: 22, name: 'character_22'},
    {id: 23, name: 'character_23'},
    {id: 24, name: 'character_24'},
    {id: 25, name: 'character_25'},
    {id: 26, name: 'character_26'},
    {id: 27, name: 'character_27'},
    {id: 28, name: 'character_28'},
    {id: 29, name: 'character_29'},
    {id: 30, name: 'character_30'},
    {id: 31, name: 'character_31'},
    {id: 32, name: 'web_1'},
    {id: 33, name: 'web_2'},
    {id: 34, name: 'web_3'},
    {id: 35, name: 'your_1'},
    {id: 36, name: 'your_2'},
    {id: 37, name: 'your_3'},
    {id: 38, name: 'your_4'},
]
  
export const steps = [
    {operation: 'add', id: 1, skipCount: 0},
    {operation: 'add', id: 2, skipCount: 0},
    {operation: 'add', id: 3, skipCount: 0},
    {operation: 'add', id: 4, skipCount: 0},
    {operation: 'add', id: 5, skipCount: 0},
    {operation: 'add', id: 6, skipCount: 0},
    {operation: 'add', id: 7, skipCount: 0},
    {operation: 'add', id: 8, skipCount: 0},
    {operation: 'add', id: 9, skipCount: 0},
    {operation: 'add', id: 10, skipCount: 0},
    {operation: 'add', id: 11, skipCount: 0},
    {operation: 'add', id: 12, skipCount: 0},
    {operation: 'add', id: 13, skipCount: 0},
    {operation: 'add', id: 14, skipCount: 0},
    {operation: 'add', id: 15, skipCount: 0},
    {operation: 'add', id: 16, skipCount: 0},
    {operation: 'add', id: 17, skipCount: 0},
    {operation: 'add', id: 18, skipCount: 0},
    {operation: 'add', id: 19, skipCount: 0},
    {operation: 'add', id: 20, skipCount: 0},
    {operation: 'add', id: 21, skipCount: 0},
    {operation: 'add', id: 22, skipCount: 0},
    {operation: 'add', id: 23, skipCount: 0},
    {operation: 'add', id: 24, skipCount: 0},
    {operation: 'add', id: 25, skipCount: 0},
    {operation: 'add', id: 26, skipCount: 0},
    {operation: 'add', id: 27, skipCount: 0},
    {operation: 'add', id: 28, skipCount: 0},
    {operation: 'add', id: 29, skipCount: 0},
    {operation: 'add', id: 30, skipCount: 0},
    {operation: 'add', id: 31, skipCount: 0},
    {operation: 'remove', id: 22, skipCount: 0},
    {operation: 'remove', id: 21, skipCount: 0},
    {operation: 'remove', id: 20, skipCount: 0},
    {operation: 'remove', id: 19, skipCount: 0},
    {operation: 'remove', id: 18, skipCount: 0},
    {operation: 'remove', id: 17, skipCount: 0},
    {operation: 'add', id: 32, skipCount: 0},
    {operation: 'add', id: 33, skipCount: 0},
    {operation: 'add', id: 34, skipCount: 0},
    {operation: 'remove', id: 34, skipCount: 5},
    {operation: 'remove', id: 33, skipCount: 0},
    {operation: 'remove', id: 32, skipCount: 0},
    {operation: 'add', id: 35, skipCount: 0},
    {operation: 'add', id: 36, skipCount: 0},
    {operation: 'add', id: 37, skipCount: 0},
    {operation: 'add', id: 38, skipCount: 0},
    {operation: 'remove', id: 38, skipCount: 5},
    {operation: 'remove', id: 37, skipCount: 0},
    {operation: 'remove', id: 36, skipCount: 0},
    {operation: 'remove', id: 35, skipCount: 0},
    {operation: 'add', id: 17, skipCount: 0},
    {operation: 'add', id: 18, skipCount: 0},
    {operation: 'add', id: 19, skipCount: 0},
    {operation: 'add', id: 20, skipCount: 0},
    {operation: 'add', id: 21, skipCount: 0},
    {operation: 'add', id: 22, skipCount: 0},
]
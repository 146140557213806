/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'

import * as S from './styles'

type Props = {
  onPress: (type: 'home' | 'latest' | 'about' | 'companies') => void
}

const Footer: FC<Props> = ({ onPress }) => {
  return (
    <S.Container>
      <S.Logo>© 2022 HIGOR BELEM</S.Logo>

      <S.Nav>
        <S.Item onClick={() => onPress('home')}>HOME</S.Item>
        <S.Item onClick={() => onPress('latest')}>LATEST APPS</S.Item>
        <S.Item onClick={() => onPress('about')}>ABOUT ME</S.Item>
        <S.Item onClick={() => onPress('companies')}>COMPANIES</S.Item>
      </S.Nav>
    </S.Container>
  )
}

export default Footer

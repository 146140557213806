import { useAnimations, useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three';

export default function Diorama({
  position,
  rotation,
  scale
}) {
  const [posData, setPosData] = useState({ posX: 0, posY: 0 , scale: 0})
  const [direction, setDirection] = useState(1)
  const [speed, setSpeed] = useState(0.0007)

  const { nodes, materials, animations } = useGLTF('/diorama_camila.glb')

  const ref = useRef()

  const cowRef = useRef()
  const girlRef = useRef()
  const birdRef = useRef()

  const { actions: cowActions } = useAnimations(animations, cowRef)

  const { actions: girlActions } = useAnimations(animations, girlRef)

  const { actions: birdActions } = useAnimations(animations, birdRef)

  useFrame(() => {
    if(ref.current){
      if(ref.current.rotation.y < -1){
        setDirection(1)
      }else if (ref.current.rotation.y > 0){
        setDirection(-1)
      }


      ref.current.rotation.y += direction * speed
      //ref.current.rotateOnAxis(new Vector3(0, 1, 0).normalize(), speed);
    }
  })

  useEffect(() => {
    cowActions['ArmatureAction.001'].play()
    birdActions['Armature.birdAction.001'].play()
  }, [])

  useEffect(() => {
    onScreenSizeChanged()
    window.addEventListener('resize', onScreenSizeChanged);
    return () => window.removeEventListener('resize', onScreenSizeChanged);
  }, [])

  const onScreenSizeChanged = () => {
    const ratio = window.innerWidth / window.innerHeight;
    setPosData(getDataFromRatio(ratio))
  }

  const getDataFromRatio = (ratio) => {
    if(ratio > 1.822) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.515) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.359) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 1.096) return { posX: 0, posY: 0 , scale: 0 }
    if(ratio > 0.779) return { posX: 0, posY: 0 , scale: -0.1 }
    if(ratio > 0.569) return { posX: -2.8, posY: 0 , scale: -0.1 }
    if(ratio > 0.501) return { posX: -3, posY: 1 , scale: -0.15 }
    return { posX: -3, posY: 1 , scale: -0.15 }
  }

  return (
    <group ref={ref} position={[position[0] + posData.posX, position[1] + posData.posY, position[2]]} scale={scale + posData.scale} rotation={rotation}>
      <mesh
        receiveShadow
        geometry={nodes.ground.geometry}
        material={materials.ground_2}
      />

      <mesh
        castShadow
        geometry={nodes.rive.geometry}
        material={materials.water}
      />

       <mesh
        castShadow
        geometry={nodes.fence001.geometry}
        material={materials.fence}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.passeio001.geometry}
        material={materials.passei}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.house001.geometry}
        material={materials.house}
      />

      <mesh
        receiveShadow
        geometry={nodes.roof001.geometry}
        material={materials.house_roof}
      />

      <mesh
        receiveShadow
        geometry={nodes.door001.geometry}
        material={materials.fence}
      />

      <mesh
        receiveShadow
        geometry={nodes.window002.geometry}
        material={materials.fence}
      />

      <mesh
        receiveShadow
        geometry={nodes.window003.geometry}
        material={materials.fence}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tree_bark_1001.geometry}
        material={materials.tree_bark}
      />
      <mesh
        castShadow
        geometry={nodes.tree_leafs_1001.geometry}
        material={materials.tree_leafs}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tree_bark_2001.geometry}
        material={materials.tree_bark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tree_leafs_2001.geometry}
        material={materials.tree_leafs}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tree_bark_3001.geometry}
        material={materials.tree_bark}
      />
      <mesh
        castShadow
        geometry={nodes.tree_leafs_3001.geometry}
        material={materials.tree_leafs}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pit001.geometry}
        material={materials.well}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pit_roof001.geometry}
        material={materials.fence}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bucket001.geometry}
        material={materials.fence}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bucket_water001.geometry}
        material={materials.water}
      />

      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch006.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch007.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch008.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch009.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch010.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.grass_patch011.geometry}
          material={materials.grass}
        />
      </group>

      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.stone004.geometry}
          material={materials.stone}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.stone005.geometry}
          material={materials.stone}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.stone006.geometry}
          material={materials.stone}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.stone007.geometry}
          material={materials.stone}
        />
      </group>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.food_board001.geometry}
        material={materials.fence}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.food001.geometry}
        material={materials.food}
      />

      <group ref={birdRef}>
        <primitive object={nodes.main} />
        <skinnedMesh
          castShadow
          receiveShadow
          material={materials.bird}
          skeleton={nodes.bird001.skeleton}
          geometry={nodes.bird001.geometry}
        />
      </group>

      <group ref={girlRef}>
        <primitive object={nodes.Bone} />
        <skinnedMesh
          castShadow
          receiveShadow
          material={materials.girl}
          skeleton={nodes.kid001.skeleton}
          geometry={nodes.kid001.geometry}
        />
      </group>

      <group ref={cowRef}>
        <primitive object={nodes.Bone009} />
        <skinnedMesh
          castShadow
          receiveShadow
          material={materials.cow}
          skeleton={nodes.cow001.skeleton}
          geometry={nodes.cow001.geometry}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/diorama_camila.glb')

/* eslint-disable no-use-before-define */
import { FC, useEffect, useRef, useState } from 'react'

import * as S from './styles'
import PhoneDisplay from '../PhoneDisplay'
import reactIcon from '../../assets/imgs/techs/react.svg'
import nodejsIcon from '../../assets/imgs/techs/nodejs.svg'
import reanimatedIcon from '../../assets/imgs/techs/reanimated.png'
import firebaseIcon from '../../assets/imgs/techs/firebase.svg'
import codepushIcon from '../../assets/imgs/techs/codepush.svg'
import pendoIcon from '../../assets/imgs/techs/pendo.svg'
import reduxIcon from '../../assets/imgs/techs/redux.svg'
import googlePlayIcon from '../../assets/imgs/google_play_logo.png'
import appStoreIcon from '../../assets/imgs/app_store_logo.png'
import useOnScreen from '../../hooks/OnScreen'

const techIcons = {
  react: reactIcon,
  nodejs: nodejsIcon,
  reanimated: reanimatedIcon,
  redux: reduxIcon,
  firebase: firebaseIcon,
  codepush: codepushIcon,
  pendo: pendoIcon
}

const distributionIcons = {
  google_play: googlePlayIcon,
  app_store: appStoreIcon,
  web: appStoreIcon
}

type techType = keyof typeof techIcons

type distributionType = keyof typeof distributionIcons

export type AppSectionType = {
  showLatestTitle: boolean
  latestTitle: string
  title: string
  description: string
  mainTech: string
  video: any
  top: number
  left: boolean
  colors: {
    background: string
    title: string
    text: string
    tech: string
  }
  techs: techType[]
  distribution: {
    type: distributionType
    link: string
    soon?: boolean
  }[]
  index: number
}

const AppSection: FC<AppSectionType> = ({
  showLatestTitle,
  latestTitle,
  title,
  description,
  mainTech,
  video,
  top,
  left,
  colors,
  techs,
  distribution,
  index
}) => {
  const [infoBelow, setInfoBelow] = useState(false)

  const ref = useRef<any>()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    onScreenSizeChanged()
    window.addEventListener('resize', onScreenSizeChanged)

    return () => {
      window.removeEventListener('resize', onScreenSizeChanged)
    }
  }, [])

  const onScreenSizeChanged = () => {
    const ratio = window.innerWidth / window.innerHeight
    setInfoBelow(ratio <= 1.096)
  }

  return (
    <S.SectionContainer clipLeft={left} top={top} color={colors.background}>
      <S.BgImage left={left} />
      <S.TechTitle left={left} color={colors.tech}>
        {mainTech}
      </S.TechTitle>

      {showLatestTitle && <S.SectionTitle>{latestTitle}</S.SectionTitle>}

      <PhoneDisplay video={video} fromLeft={left} index={index} />

      <S.InfoContainer
        left={left}
        opacity={isVisible ? 1 : 0}
        below={infoBelow}
        ref={ref}
      >
        <S.InfoTitle color={colors.title}>{title}</S.InfoTitle>

        <S.InfoDescription color={colors.text}>{description}</S.InfoDescription>

        <S.TechContainer>
          <S.OtherTechTitle color={colors.text}>Techs:</S.OtherTechTitle>

          <S.TechContent>
            {techs.map(item => (
              <S.TechImg src={techIcons[item]} />
            ))}
          </S.TechContent>
        </S.TechContainer>

        <S.DistributionContainer>
          {distribution.map(item => (
            <S.DistributionButton
              onClick={() => window.open(item.link, '_blank')}
              disabled={item.soon}
            >
              <S.DistributionImg src={distributionIcons[item.type]} />

              {item.soon && (
                <S.DistributionSoonContainer>
                  <S.DistributionSoonText>Coming soon</S.DistributionSoonText>
                </S.DistributionSoonContainer>
              )}
            </S.DistributionButton>
          ))}
        </S.DistributionContainer>
      </S.InfoContainer>
    </S.SectionContainer>
  )
}

export default AppSection

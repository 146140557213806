import styled from 'styled-components'

import { colors } from '../../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 120px 15% 120px 15%;
  max-width: 1920px;

  @media only screen and (max-width: 900px) {
    padding: 120px 5% 120px 5%;
  }
`

export const Title = styled.h2`
  display: flex;
  text-align: center;
  font-size: 45px;
  color: ${colors.main};
  font-family: gotham, sans-serif;

  @media only screen and (max-width: 730px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 32px;
  }
`

export const AboutText = styled.h2`
  display: flex;
  text-align: start;
  color: ${colors.text};
  font-family: gotham, sans-serif;
  padding: 20px 0 0 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;

  @media only screen and (max-width: 730px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 18px;
  }
`

export const SkillsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 40px 0 40px 0;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0 20px;
  gap: 20px;
  margin-top: 20px;
`

export const Contact = styled.div`
  display: flex;
  align-items: center;
`

export const ContactImage = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`

export const ContactLink = styled.a`
  font-size: 18px;
  color: ${colors.text};
  font-family: gotham, sans-serif;
  text-decoration: underline;
  margin-left: 15px;
  word-wrap: break-word;

  &:hover {
    cursor: pointer;
  }
`

/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import * as S from './styles'
import LoaderAnim from '../../assets/animations/loader.json'

const SuspenseLoader: FC = () => {
  const [state, seState] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      seState(1)

      setTimeout(() => {
        seState(2)
      }, 500)
    }, 1000)
  }, [])

  let width = window.innerWidth * 0.8
  if (width > 300) width = 300
  const height = width * 0.5

  return (
    <S.Container state={state}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: LoaderAnim,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        width={width}
        height={height}
      />
    </S.Container>
  )
}

export default SuspenseLoader

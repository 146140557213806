import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
`

export const Image = styled.img<{ visible?: boolean }>`
  height: 40px;
  width: 40px;
  object-fit: contain;
  animation: ${pulse} 1.5s ease-in-out infinite alternate;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.5s;
`

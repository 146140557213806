import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { BackSide, Euler, sRGBEncoding } from 'three';

export default function Phone({
  position,
  rotation,
  scale,
  containerPosition,
  video,
  fromLeft,
  index,
  show
}) {
  const [posData, setPosData] = useState({ startPosition: fromLeft ? -10 : 10 , endPosition: fromLeft ? -2 : 2})

  const { nodes, materials } = useGLTF('/phone.glb')

  const [videoInstance] = useState(() => {
    const vid = document.createElement("video");
    //vid.src = videoFolclore;
    vid.id = `video-${index}`
    vid.crossOrigin = "anonymous";
    vid.loop = true;
    vid.muted = true;
    //vid.autoplay = true;
    vid.playsInline = true;
    vid.innerHTML = `<source type="video/mp4" src="${video}" />`
    //vid.play();
    return vid;
  })

  const ref = useRef()

  useFrame(() => {
    const deadzoneHeight = 50;
    const containerHeight = window.innerHeight - deadzoneHeight;

    const currentScroll = window.scrollY + window.innerHeight;

    if(currentScroll > containerPosition) {
      const currentPosInsideContainer = currentScroll - containerPosition;
      const percentage = currentPosInsideContainer / containerHeight

      const initialPosition = posData.startPosition;

      let position = initialPosition * (1 - percentage) + posData.endPosition
      let rotation = Math.PI * percentage * (fromLeft ? 1 : -1)

      if(fromLeft){
        if(position > posData.endPosition) position = posData.endPosition
      }else{
        if(position < posData.endPosition) position = posData.endPosition
      }
      if(Math.abs(rotation) > Math.PI) rotation = Math.PI

      ref.current.rotation.y = rotation;
      ref.current.position.x = position;
    }
  })

  useEffect(() => {
    onScreenSizeChanged()
    window.addEventListener('resize', onScreenSizeChanged);
    return () => window.removeEventListener('resize', onScreenSizeChanged);
  }, [])

  useEffect(() => {
    if(videoInstance){
      if(show){
        videoInstance.play();
      }else{
        videoInstance.pause();
      }
    }
  }, [show])

  const onScreenSizeChanged = () => {
    const ratio = window.innerWidth / window.innerHeight;
    setPosData(getDataFromRatio(ratio))
  }

  const getDataFromRatio = (ratio) => {
    if(ratio > 1.822) return { startPosition: fromLeft ? -10 : 10 , endPosition: fromLeft ? -2 : 2 }
    if(ratio > 1.515) return { startPosition: fromLeft ? -10 : 10 , endPosition: fromLeft ? -2 : 2 }
    if(ratio > 1.359) return { startPosition: fromLeft ? -5 : 5 , endPosition: fromLeft ? -2 : 2 }
    if(ratio > 1.096) return { startPosition: fromLeft ? -5 : 5 , endPosition: fromLeft ? -2 : 2 }
    if(ratio > 0.779) return { startPosition: fromLeft ? -5 : 5 , endPosition: 0 }
    if(ratio > 0.569) return { startPosition: fromLeft ? -5 : 5 , endPosition: 0 }
    if(ratio > 0.501) return { startPosition: fromLeft ? -5 : 5 , endPosition: 0 }
    if(ratio > 0.385) return { startPosition: fromLeft ? -5 : 5 , endPosition: 0 }
    return { startPosition: fromLeft ? -5 : 5 , endPosition: 0 }
  }

  return (
    <group ref={ref} position={[-10, 0, 1]} scale={1} rotation={rotation}>
      <mesh
        geometry={nodes.screen.geometry}
        // material={materials.screen}
        position={[0, 0, -0.23]}
        rotation={new Euler(Math.PI * -0.003, Math.PI, 0)}
      >

        <meshBasicMaterial toneMapped={false} side={BackSide} >
          <videoTexture attach="map" args={[videoInstance]}  encoding={sRGBEncoding}/>  
        </meshBasicMaterial>  
      </mesh>

      <mesh
        geometry={nodes.screen_edges.geometry}
        material={materials.screen}
      />

      <mesh
        geometry={nodes.sides.geometry}
        material={materials.sides}
      />

      <mesh
        geometry={nodes.back.geometry}
        material={materials.back}
      />

      <mesh
        geometry={nodes.camera.geometry}
        material={materials.screen}
      />
    </group>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-use-before-define */
import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './styles'

type Props = {}

const Header: FC<Props> = ({}) => {
  const history = useHistory()

  return (
    <S.Container>
      <S.Logo onClick={() => history.push('/')}>HIGOR BELEM</S.Logo>
    </S.Container>
  )
}

export default Header

/* eslint-disable no-use-before-define */
import { FC } from 'react'

import * as S from './styles'
import chevronIcon from '../../assets/icons/chevron-down.svg'

export type ArrowAnimationType = {
  visible?: boolean
}

const ArrowAnimation: FC<ArrowAnimationType> = ({ visible }) => {
  return <S.Image src={chevronIcon} visible={visible} />
}

export default ArrowAnimation
